/* eslint-disable no-console,no-undef, camelcase */
/* eslint-disable react/no-unknown-property */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import OnDemandCards from "../OnDemandDetailsPage/onDemandCards";
import { Layout, Container } from "../CompanyLiveEvents/styles";
import {arrowIconDouble} from '../../../utils/icons';
import Slider from "react-slick";
import {SliderContainerOndemand} from '../../MainEvents/styles';

class OnDemandDetailsMain extends Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      currentSlide:1,
    };
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id,
    });
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1,
    });
  };
  
  handleBeforeChange = (oldIndex, newIndex) => {
    // Update the current slide index when the slider changes
    this.setState({ currentSlide: newIndex + 1 });
  };

  render() {
    const { history, link, eventData, heading } = this.props;
    const { onHover } = this.state;
    let healthEvent = {
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: true,
      draggable: true,
      adaptiveHeight: true,
      nextArrow: <div className={"left-arrow slick-next"} />,
      prevArrow: <div className={"left-arrow slick-prev"} />,
      beforeChange: this.handleBeforeChange,
      responsive: [
        {
          breakpoint: 400,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            infinite: true,
            speed: 500,
            draggable: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
          },
        },
      ],
    };
    return (
      <>
        <Layout margin={"0"} padding={"0"}>
          <div className="headingMain">
            <div className="heading" >{heading?heading:"Recommended Events"}</div>
            {eventData?.length>0?<div className="arrows">
              <div className="iconBackground" onClick={() => this.slider.slickPrev()} style={{transform:"rotate(180deg)"}}>
                {arrowIconDouble()}
              </div>
              <div className="text">{this.state.currentSlide} / {eventData?.length}</div>
              <div className="iconBackground" onClick={() => this.slider.slickNext()} >
                {arrowIconDouble()}
              </div>
            </div>:null}
          </div>
        </Layout>
        <Container>
          {eventData?.length > 0?
            <SliderContainerOndemand
              display="flex"
              content="center"
              shadow="0px 8px 25px 0px #0D427040"
            >
              <Slider {...healthEvent} ref={(slider) => (this.slider = slider)}>
                {
                  eventData?.length > 0 &&
                          eventData.map((video, index) => (
                            <div className="ondemand" key={index}>
                              <OnDemandCards
                                video={video}
                                key={index}
                                history={history}
                                grid={0}
                                link={link}
                                onEnterMouse={this.onEnterMouse}
                                onLeaveMouse={this.onLeaveMouse}
                                onHover={onHover}
                                length={index}
                                width="399px"
                              />
                            </div>
                          ))}
              </Slider>
            </SliderContainerOndemand>:null}
        </Container>
      </>
    );
  }
}

OnDemandDetailsMain.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func,
  link: PropTypes.string,
  onEnterMouse: PropTypes.func.isRequired,
  onLeaveMouse: PropTypes.func.isRequired,
  eventData: PropTypes.array,
  heading: PropTypes.string
};

export default connect(null,null)(withTranslation()(OnDemandDetailsMain));